import React, { useState } from "react";
import { Link } from "react-router-dom";

import DownArrow from "../assets/img/down-arrow.png";
import menuIcon from "../assets/img/meni-icon.png";
import closeIcon from "../assets/img/close.png";
import Logo from "../assets/img/logo.jpg";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <nav className="bg-white h-20 cursor-pointer flex justify-between z-10 items-center px-5 lg:px-20 fixed w-screen shadow-sm">
      <div className="logo flex items-center gap-3 relative">
        <Link to="/" className="flex items-center">
          <img src={Logo} alt="Logo" className="h-20" />
          {/* <span className="">PRIMA LEGAL</span> */}
        </Link>
      </div>
      <div className="menu hidden sm:block">
        <ul className="flex gap-8">
          <Link to="/">
            <li className="cursor-pointer hover:text-red-600 font-semibold text-sm">Home</li>
          </Link>
          <Link to="/about">
            <li className="cursor-pointer hover:text-red-600 font-semibold text-sm">About</li>
          </Link>
          {/* <Link to="/expertise">
          <li className="cursor-pointer hover:text-red-600 font-semibold text-sm">Expertise</li>
        </Link> */}
          <li className="cursor-pointer hover:text-red-600 font-semibold text-sm relative group">
            <span className="flex item-enter">
              <span> Legal Service </span>
              <img src={DownArrow} alt="Down Arrow" className="h-5" />
            </span>
            <ul className="absolute hidden group-hover:block bg-white min-w-40 shadow-xl p-3 rounded-lg leading-8">
              <Link to="/agreement-drafting">
                <li>Agreement Drafting</li>
              </Link>
              <Link to="/legal-guidance">
                <li>Legal Guidance</li>
              </Link>
              <Link to="/legal-aid">
                <li>Legal Aid</li>
              </Link>
              <Link to="/competition">
                <li>Competitions</li>
              </Link>
              <Link to="/call-for-papers">
                <li>Call for Papers</li>
              </Link>
              <Link to="/webinars">
                <li>Webinars</li>
              </Link>
            </ul>
          </li>
          <Link to="/blog">
            <li className="cursor-pointer font-semibold text-sm hover:text-red-600">Blog</li>
          </Link>
          <Link to="/contact">
            <li className="cursor-pointer font-semibold text-sm hover:text-red-600">Contact</li>
          </Link>
        </ul>
      </div>
      <img src={menuIcon} alt="Menu Icon" className="toggle-menu h-5 cursor-pointer block sm:hidden" onClick={toggleMobileMenu} />

      {/* MOBILE MENU  */}
      {isMobileMenuOpen && (
        <div className="mobileMenu block sm:hidden absolute right-0 top-0 bg-white h-screen cursor-default w-screen">
          <img src={closeIcon} alt="Close Icon" className="mobile-icon h-6 absolute right-10 top-10 cursor-pointer bg-red-400 rounded" onClick={closeMobileMenu} />

          <ul className="flex flex-col justify-center items-center h-screen gap-10">
            <Link to="/">
              <li className="cursor-pointer hover:text-red-600 font-semibold text-xl">Home</li>
            </Link>
            <Link to="/about">
              <li className="cursor-pointer hover:text-red-600 font-semibold text-xl">About</li>
            </Link>
            {/* <Link to="/expertise">
          <li className="cursor-pointer hover:text-red-600 font-semibold text-sm">Expertise</li>
        </Link> */}
            <li className="cursor-pointer hover:text-red-600 font-semibold text-lg relative group">
              <span className="flex item-enter">
                <span> Legal Service </span>
                <img src={DownArrow} alt="Down Arrow" className="h-8" />
              </span>
              <ul className="absolute hidden group-hover:block scroll-ml-18  bg-white w-52 shadow-xl p-3 rounded-lg leading-8">
                <Link to="/agreement-drafting">
                  <li className="text-sm mb-2">Agreement Drafting</li>
                </Link>
                <Link to="/legal-guidance">
                  <li className="text-sm mb-2">Legal Guidance</li>
                </Link>
                <Link to="/legal-aid">
                  <li className="text-sm mb-2">Legal Aid</li>
                </Link>
                <Link to="/competition">
                  <li className="text-sm mb-2">Competitions</li>
                </Link>
                <Link to="/call-for-papers">
                  <li className="text-sm mb-2">Call for Papers</li>
                </Link>
                <Link to="/webinars">
                  <li className="text-sm mb-2">Webinars</li>
                </Link>
              </ul>
            </li>
            <Link to="/blog">
              <li className="cursor-pointer font-semibold text-xl hover:text-red-600">Blog</li>
            </Link>
            <Link to="/contact">
              <li className="cursor-pointer font-semibold text-xl hover:text-red-600">Contact</li>
            </Link>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
