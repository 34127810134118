import React from "react";
import Navbar from "../components/Navbar";

import agreement from "../assets/img/agreement-bg.jpg";
import bannerImg2 from "../assets/img/banner2.jpg";
import BannerSection from "../components/BannerSection";
import Footer from "../components/Footer";
import AreaOfPractice from "../components/AreaOfPractice";
import WhyChooseUs from "../components/WhyChooseUs";

// Area Of Practice Images
import Finalization from "../assets/img/finalization.png";
import Drafting from "../assets/img/drafting.png";
import Negotiation from "../assets/img/negotiation.png";
import Review from "../assets/img/review.png";
import Consultation from "../assets/img/consultation.png";

const AgreementDrafting = () => {
  return (
    <div>
      <Navbar />

      <BannerSection
        bannerImg={agreement}
        bannerImg2={bannerImg2}
        title="Ensuring Every Contract is Detailed, Clear, and Legally Sound"
        subtitle="At Prima Legal, we specialize in crafting comprehensive and clear agreements tailored to meet your specific needs. Protect your interests with legally sound contracts."
      />

      <section className="p-5 sm:p-20 mt-5">
        <p className="my-10">
          <span className="text-red-600 font-bold">At Prima Legal</span> , we
          understand the importance of having clear, concise, and legally sound
          agreements. Our Agreement Drafting service ensures that all contracts
          and agreements are tailored to meet your specific needs while
          protecting your interests.
        </p>
        <p className="my-10">
          Our team is adept at drafting, reviewing, and negotiating various
          types of agreements, including:
        </p>

        <ul>
          <li className="mb-3">
            1. <span className="text-red-600">Employment Contracts :</span>
            Drafting agreements that outline the terms of employment, ensuring
            both employer and employee rights are protected.
          </li>
          <li className="mb-3">
            2.{" "}
            <span className="text-red-600">
              Non-Disclosure Agreements (NDAs):
            </span>{" "}
            Creating agreements to safeguard sensitive information and maintain
            confidentiality.
          </li>
          <li className="mb-3">
            3. <span className="text-red-600">Service Agreements:</span>{" "}
            Structuring contracts that define the terms and conditions of
            service provision.
          </li>
          <li className="mb-3">
            4. <span className="text-red-600">Partnership Agreements:</span>{" "}
            Formulating agreements that delineate the responsibilities and
            profit-sharing among partners.
          </li>
          <li className="mb-3">
            5.{" "}
            <span className="text-red-600">Sale and Purchase Agreements:</span>{" "}
            Drafting documents that govern the sale and purchase of goods or
            services, ensuring clarity and fairness.
          </li>
        </ul>
      </section>

     <WhyChooseUs/>

      <section className="p-5 sm:20">
        <AreaOfPractice
          title="How We Work"
          subTitle=""
          service1Img={Consultation}
          service1Title="Consultation"
          service1Desc="We begin by understanding your needs through detailed consultations."
          service2Img={Drafting}
          service2Title="Drafting"
          service2Desc="Our experts draft agreements that meet your specific requirements."
          service3Img={Review}
          service3Title="Review"
          service3Desc="We review the drafts with you to ensure they align with your expectations."
          service4Img={Negotiation}
          service4Title="Negotiation"
          service4Desc="We assist in negotiating terms to achieve mutually beneficial outcomes."
          service5Img={Finalization}
          service5Title="Finalization"
          service5Desc="Once all parties are satisfied, we finalize and formalize the agreements."
        />
      </section>

      <Footer />
    </div>
  );
};

export default AgreementDrafting;
