import React from "react";

const AreaOfPractice = ({
  title,
  subTitle,
  service1Img,
  service1Title,
  service1Desc,
  service2Img,
  service2Title,
  service2Desc,
  service3Img,
  service3Title,
  service3Desc,
  service4Img,
  service4Title,
  service4Desc,
  service5Img,
  service5Title,
  service5Desc,
}) => {
  return (
    <div>
      <section className="p-5 sm:p-20">
        <div className="text-center">
          <h3 className="sm:text-5xl text-2xl mb-4 font-thin ">{title}</h3>
          <p className="mt-3 sm:text-5xl text-2xl text-red-600 font-semibold">
            {subTitle}
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 grid-rows-2 gap-4 mt-20">
          <div className="col-span-1 sm:col-span-2 lg:col-span-1 row-span-1 bg-blue-100 p-4 rounded-lg">
            <img src={service1Img} className="h-8" />
            <p className="text-xl font-semibold mb-2">{service1Title}</p>
            <p>{service1Desc}</p>
          </div>
          <div className="col-span-1 sm:col-span-1 lg:col-span-1 row-span-1 bg-green-100 p-4 rounded-lg">
            <img src={service2Img}  className="h-8" />
            <p className="text-xl font-semibold mb-2">{service2Title}</p>
            <p>{service2Desc}</p>
          </div>
          <div className="col-span-1 sm:col-span-1 lg:col-span-1 row-span-2 bg-yellow-100 p-4 rounded-lg">
            <img src={service3Img}  className="h-8" />
            <p className="text-xl font-semibold mb-2">{service3Title}</p>
            <p>{service3Desc}</p>
          </div>
          <div className="col-span-1 sm:col-span-1 lg:col-span-1 row-span-1 bg-red-100 p-4 rounded-lg">
            <img src={service4Img}  className="h-8" />
            <p className="text-xl font-semibold mb-2">{service4Title}</p>
            <p>{service4Desc}</p>
          </div>
          <div className="col-span-1 sm:col-span-2 lg:col-span-1 row-span-1 bg-purple-100 p-4 rounded-lg">
            <img src={service5Img}  className="h-8" />
            <p className="text-xl font-semibold mb-2">{service5Title}</p>
            <p>{service5Desc}</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AreaOfPractice;
