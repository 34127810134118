import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import client from "../client";
import BlockContent from "@sanity/block-content-to-react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { AiOutlineClose } from "react-icons/ai";

import "./sanity.css";

// Modal Component
const Modal = ({ isOpen, onClose, post }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-5 mt-16 rounded-lg max-w-md w-full mx-2 relative">
        <div
          className="absolute cursor-pointer top-2 right-2 text-red-600"
          onClick={onClose}
        >
          <AiOutlineClose className="text-2xl" />
        </div>

        <div className="profile flex items-center flex-col">
          <div className="img">
            {post.image && post.image.asset && (
              <img
                src={post.image.asset.url}
                alt={post.name}
                className="h-32 w-32 mb-4 rounded-full"
              />
            )}
          </div>
          <div className="name font-bold text-xl text-center">{post.name}</div>
        </div>

        <div className="bio h-44 overflow-auto">
          {post.bio && (
            <p className="text-sm">
              {post.bio
                .map((block) =>
                  block.children.map((span) => span.text).join(" ")
                )
                .join("\n\n")}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default function SinglePost() {
  const [singlePost, setSinglePost] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { slug } = useParams();

  useEffect(() => {
    client
      .fetch(
        `*[slug.current == "${slug}"] {
        title,
        body,
        publishedAt,
        "bio": author->bio,
        mainImage {
          asset -> {
            _id,
            url
          },
          alt
        },
        "name": author -> name,
        "image": author -> image{
          asset -> {
          url
          }
        }
      }`
      )
      .then((data) => {
        setSinglePost(data[0]);
        setIsLoading(false);
      });
  }, [slug]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Navbar />
      {isLoading ? (
        <h1 className="uppercase font-bold text-4xl tracking-wide mb-5 md:text-6xl lg:text-8xl flex items-center justify-center h-screen">
          Loading...
        </h1>
      ) : (
        <section className="p-5 sm:p-20">
          <h1 className="uppercase font-bold text-3xl  mb-10 md:text-5xl lg:text-5xl mt-5 md:pt-10 pt-24 sm:pt-12">
            {singlePost.title}
          </h1>
          <div className="flex justify-center"> 
          {singlePost.mainImage && singlePost.mainImage.asset && (
            <img
              src={singlePost.mainImage.asset.url}
              alt={singlePost.title}
              title={singlePost.title}
              className="blog__image rounded-t"
            />
          )}
          </div>

          <div className="flex gap-3 mt-6">
            <div className="profile-img">
              {singlePost.image && singlePost.image.asset && (
                <img
                  src={singlePost.image.asset.url}
                  alt={singlePost.name}
                  className="h-20 w-20 mb-4 rounded-full cursor-pointer"
                  onClick={handleOpenModal}
                />
              )}
            </div>

            <div className="name-and-publish mt-2">
              <p className="text-sm font-bold mb-0 text-red-600">
                Author:{" "}
                <span
                  className="font-normal cursor-pointer"
                  onClick={handleOpenModal}
                >
                  {singlePost.name}
                </span>
              </p>
              <span className="font-normal text-xs">
                {new Date(singlePost.publishedAt).toLocaleDateString("en-US", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                })}
              </span>
            </div>
          </div>

          <div className="block__content">
            <BlockContent
              blocks={singlePost.body}
              projectId="2hp9gld0"
              dataset="production"
            />
          </div>

          <button className="mt-12">
            <Link
              to="/blog"
              className="bg-red-600 hover:bg-red-500 transition-all px-6 py-1 rounded-sm text-white mt-10"
            >
              Read more articles
            </Link>
          </button>
        </section>
      )}

      <Footer />

      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        post={singlePost}
      />
    </>
  );
}
