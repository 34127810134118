import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const AdminLogin = () => {
  return (
    <>
      <Navbar />
      <section className="p-5 sm:p-20 h-64 flex items-center justify-center">
        <a href="https://primalegal.sanity.studio/">
          <button className="bg-red-600 hover:bg-red-500 transition-all px-6 py-1 rounded-sm text-white mt-10">
            Admin Panel
          </button>
        </a>
      </section>
      <Footer />
    </>
  );
};

export default AdminLogin;
