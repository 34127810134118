import React from "react";

function BannerSection({ bannerImg, bannerImg2, title, subtitle }) {
  return (
    <div>
      <img src={bannerImg} alt="banner img" className="w-screen object-cover" style={{ height: "100vh" }} />

      {/* section-1 */}
      <div className="absolute top-16 left-0 h-full w-full mt-10 flex p-5 md:p-20">
        <div className="w-5/6">
          <h1 className="text-white text-4xl font-bold">{title}</h1>
          <p className="text-white text-lg mt-2">{subtitle}</p>
          
          <a href="mailto:contact@primalegal.co.in">
          <button className="bg-red-600 hover:bg-red-500 transition-all px-6 py-1 rounded-sm text-white mt-10">Let's Talk</button>
          </a>
          
        </div>
        <div className="hidden sm:block">
          <img src={bannerImg2} alt="banner img" className="h-80 w-96 rounded-full object-cover" />
        </div>
      </div>
    </div>
  );
}

export default BannerSection;
