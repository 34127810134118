import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import client from "../client";
import Navbar from "./Navbar";
import Footer from "./Footer";

export default function Blog() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "post"] {
        title,
        slug,
        body,
        publishedAt,
        categories[] -> {
        title,
        },
        mainImage {
          asset -> {
            _id,
            url
          },
          alt
        },
        "name": author -> name
      }`
      )
      .then((data) => setPosts(data))
      .catch(console.error);
  }, []);

  const blogPosts = posts.filter((post) =>
    post.categories.some(
      (category) => category.title.toLowerCase() === "blogging"
    )
  );

  return (
    <>
      <Navbar />

      <section className="p-5 sm:p-20 h-96 bg-blue-950 flex justify-center flex-col">
        <h1 className="text-5xl text-white">
          Prima Legal Blog: Your Legal Compass
        </h1>
      </section>

      <section className="mt-10 p-5 sm:p-20">
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
          {blogPosts
            .sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt)) // Sort posts by published date, most recent first
            .map((post) => (
              <article
                key={post.slug.current}
                className="bg-gray-100 rounded-lg p-5"
              >
                <img
                  src={post.mainImage.asset.url}
                  alt={post.title}
                  className="rounded-xl h-60 w-1/1"
                />
                <Link to={`/blog/${post.slug.current}`}>
                  <h4 className="text-xl mt-2" style={{ fontWeight: "bold" }}>
                    {post.title}
                  </h4>
                </Link>

                {/* {post.categories !== null && (
        <ul>
          {post.categories.map((category, index) => (
            <li key={index}>{category.title}</li>
          ))}
        </ul>
      )} */}

                <div className="">
                  {/* <p className="text-sm font-bold">
                    Author: <span className="font-normal">{post.name}</span>
                  </p> */}
                </div>

                {/* <p className="text-sm font-bold m-0 mt-10">
                    <span className="font-normal">
                      {new Date(post.publishedAt).toLocaleDateString("en-US", {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                      })}
                    </span>
                  </p> */}

                <button className="mt-5 mb-2">
                  <Link
                    to={`/blog/${post.slug.current}`}
                    className="bg-red-600 hover:bg-red-500 transition-all px-6 py-1 rounded-sm text-white mt-10"
                  >
                    Read Full Article
                  </Link>
                </button>
              </article>
            ))}
        </div>
      </section>
      <Footer />
    </>
  );
}
