import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import BannerSection from "../components/BannerSection";

import bannerImg from "../assets/img/banner-3.png";
import bannerImg2 from "../assets/img/banner2.jpg";
import CallNow from "../components/CallNow";
import Team from "../components/Team";

const AboutPage = () => (
  <div>
    <Navbar />

    <BannerSection
      bannerImg={bannerImg}
      bannerImg2={bannerImg2}
      title="Prima Legal Services – India’s Leading Legal Services Organization"
      subtitle="Expertise, Integrity, and Excellence in Legal Solutions"
    />

    <section className="p-5 sm:p-20 mt-5">
      <h3 className="sm:text-5xl text-2xl mb-4 font-thin text-center">Our Story</h3>
       

       <p className="mb-4">
       <span className="text-red-600">Prima Legal</span> has aimed to reach the benchmarks in legal excellence throughout India. Our firm's journey is a testament to our unwavering commitment to legal proficiency, client satisfaction, and community impact. Over the years, our legal practitioner has achieved significant milestones that highlight our growth and influence within the legal landscape. From landmark cases to pioneering legal strategies, each milestone reflects our dedication to upholding justice and serving our clients with integrity and diligence. Prima Legal remains steadfast in its mission to provide unparalleled legal services that meet the evolving needs of our clients and exceed expectations in every endeavor.
       </p>

       <p className="mb-4">
       We believe to serve people and hence, we are open to all and feel proud with our client’s testimonials so, hire lawyer in Kolkata from our organization. Our experienced Paralegal experts are accustomed with the different laws with this we became the one-stop legal solution to all your problems. Moreover, we cover every legal forte for our clients and keep ourselves updated from time to time. For ages people consider law as the most complex component of the society which makes life complicated and confusing for the dwellers. Residing in a democratic nation like India, people must abide by the norms and regulations prescribed by the government. But, there are many issues where people unknowingly break laws being ignorant of the fundamental principles of our land. Law is a complex whole but, it is not, as people assume it. Prima Legal services clarifies these false beliefs of the people and strives to eliminate the fear from the mind of the people. Prima Legal services, a legal services organization considers truth as the basic foundation of any case and proceeds onward with correct evidences and judicious suggestions. Starting from industrial to governmental and residential issues, we provide expertise solution to all our clients, and with this our team aims to achieve the foremost position in the Indian legal platform.
       </p>

       <p className="mb-4">
   <span className="text-red-600">Prima Legal</span> is also open for legal blogs from all the sectors and would be conducting various events which would help students who wish to pursue their career in Law and is always open to guide them. Prima Legal would be organising competitions for legal practitioners and students throughout the world.
       </p>

      
    </section>

   <Team/>

    <section className="p-5 sm:p-20">
      <h3 className="sm:text-5xl text-2xl mb-11 font-thin text-center font-bold">Client Approach</h3>
      <section class="relative">
        <div class="w-full max-w-7xl px-4 md:px-5 lg:px-5 mx-auto">
          <div class="w-full justify-start items-center gap-8 grid lg:grid-cols-2 grid-cols-1">
            <div class="w-full flex-col justify-start lg:items-start items-center gap-10 inline-flex">
              <div class="w-full flex-col justify-start lg:items-start items-center gap-4 flex">
                <h2 class="text-gray-900 text-4xl font-manrope leading-normal lg:text-start text-center">Building Stronger Communities through Collaboration and Empowerment</h2>
                <p class="text-gray-500 text-base font-normal leading-relaxed lg:text-start text-center">
                  At Prima Legal, we take a personalized approach to legal services, recognizing that every client and case is unique. Our dedicated team works closely with
                  each client to understand their specific needs, concerns, and objectives. By building strong relationships based on trust and communication, we ensure that our
                  clients feel supported throughout their legal journey. We prioritize clear and open dialogue, providing transparent guidance and realistic expectations from the
                  outset. Whether navigating complex litigation, negotiating agreements, or providing advisory services, we are committed to delivering tailored solutions that
                  align with our clients' best interests. At Prima Legal, your success and satisfaction are our primary goals.
                </p>
              </div>
            </div>
            <img class="lg:mx-0 mx-auto h-full" alt="community" src="https://pagedone.io/asset/uploads/1717751272.png" />
          </div>
        </div>
      </section>
    </section>

    <CallNow />
    <Footer />
  </div>
);
export default AboutPage;
