import React from 'react'

const CallNow = () => (
  <div className="flex items-center justify-center">
    <section className="p-5 w-9/12 sm:p-20 mt-5 mb-24 rounded-xl bg-slate-200 flex flex-col items-center">
      <h1 className="text-2xl font-semibold text-center">Get Your Legal Issues Solved by Prima Legal Services & Company</h1>
      <a href="mailto:contact@primalegal.co.in">
      <button className="bg-red-600 hover:bg-red-500 transition-all px-6 py-1 rounded-sm text-white mt-10">Email us</button>
      </a>
    </section>
  </div>
);

export default CallNow
