import competition from "../assets/img/banner-6.png";
import bannerImg2 from "../assets/img/banner2.jpg";
import BannerSection from "../components/BannerSection";
import CallNow from "../components/CallNow";
import AreaOfPractice from "../components/AreaOfPractice";

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import client from "../client";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default function Competition() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "post"] {
        title,
        slug,
        body,
        categories[] -> {
        title,
        },
        mainImage {
          asset -> {
            _id,
            url
          },
          alt
        }
      }`
      )
      .then((data) => setPosts(data))
      .catch(console.error);
  }, []);

  const competitionPosts = posts.filter((post) =>
    post.categories.some(
      (category) => category.title.toLowerCase() === "competition"
    )
  );

  return (
    <>
      <Navbar />

      <BannerSection
        bannerImg={competition}
        bannerImg2={bannerImg2}
        title="Participate, Showcase Your Skills, and Gain Recognition in the Legal Community"
        subtitle="Join our legal competitions to enhance your skills, gain recognition, and connect with the legal community"
      />

      <section className="mt-10 p-5 sm:p-20">
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
          {competitionPosts.map((post) => (
            <article
              key={post.slug.current}
              className="bg-gray-100 rounded-lg p-5"
            >
              <img
                src={post.mainImage.asset.url}
                alt={post.title}
                className="rounded-xl h-72"
              />
              <Link to={`/blog/${post.slug.current}`}>
                <h4 className="text-xl mt-2">{post.title}</h4>
              </Link>

              {/* {post.categories !== null && (
                <ul>
                  {post.categories.map((category, index) => (
                    <li key={index}>{category.title}</li>
                  ))}
                </ul>
              )} */}

              <button className="mt-5 mb-10">
                <Link
                  to={`/blog/${post.slug.current}`}
                  className="bg-red-600 hover:bg-red-500 transition-all px-6 py-1 rounded-sm text-white mt-10"
                >
                  Read More
                </Link>
              </button>
            </article>
          ))}
        </div>
      </section>

      <section className="p-5 sm:p-20">
        <h2 className="text-3xl pb-10">Our Competitions Include</h2>
        <ul>
          <li className="pb-6">
            <span className="text-red-600 font-bold">
              1. Moot Court Competitions :
            </span>{" "}
            Simulated court proceedings where participants prepare and present
            legal arguments before a panel of judges. These competitions help
            participants develop their advocacy skills and gain practical
            experience.
          </li>

          <li className="pb-6">
            <span className="text-red-600 font-bold">
              2. Legal Writing Competitions:{" "}
            </span>{" "}
            Opportunities for participants to demonstrate their legal research
            and writing skills. These competitions encourage the exploration of
            important legal issues and the development of persuasive writing
            techniques.
          </li>

          <li className="pb-6">
            <span className="text-red-600 font-bold">
              3. Debate Competitions:{" "}
            </span>{" "}
            Platforms for participants to engage in structured debates on legal
            topics. These competitions help develop critical thinking, public
            speaking, and analytical skills.
          </li>

          <li className="pb-6">
            <span className="text-red-600 font-bold">
              4. Case Study Competitions:{" "}
            </span>{" "}
            Challenges where participants analyze and propose solutions to
            complex legal scenarios. These competitions encourage
            problem-solving and application of legal principles.
          </li>
        </ul>
      </section>

      <section class="bg-gray-100 p-5 sm:p-20">
        <div class="container px-6 py-10 mx-auto">
          <h1 class="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl text-black">
            Our Commitment
          </h1>

          <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-2 xl:grid-cols-3">
            <div class="p-8 space-y-3 border-2 border-red-400  rounded-xl">
              <span class="inline-block text-red-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-8 h-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"
                  />
                </svg>
              </span>

              <h1 class="text-xl font-semiboldcapitalize text-black">
                Accessibility
              </h1>

              <p class="text-gray-900">
                We make legal services accessible to all, regardless of
                financial status. We believe that everyone deserves legal
                representation and support, and we work to remove barriers to
                access.
              </p>
            </div>

            <div class="p-8 space-y-3 border-2 border-red-400 rounded-xl">
              <span class="inline-block text-red-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-8 h-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
                  />
                </svg>
              </span>

              <h1 class="text-xl font-semiboldcapitalize text-black">
                Representation
              </h1>
              <p class="text-gray-900">
                Our team provides representation and legal assistance to ensure
                fair treatment and justice. We handle a variety of cases, from
                civil disputes to criminal defense, ensuring that our clients
                receive the support they need.
              </p>
            </div>

            <div class="p-8 space-y-3 border-2 border-red-400 rounded-xl">
              <span class="inline-block text-red-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-8 h-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                  />
                </svg>
              </span>

              <h1 class="text-xl font-semiboldcapitalize text-black">
                Community Engagement
              </h1>

              <p class="text-gray-900">
                We actively engage with communities to offer legal education and
                support. Our legal camps and workshops provide valuable
                information and resources to those in need.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="p-5 sm:20">
        <AreaOfPractice
          title="How We Work"
          subTitle=""
          service1Img=""
          service1Title="Announcement"
          service1Desc="We announce upcoming competitions through our website and social media channels."
          service2Img=""
          service2Title="Registration"
          service2Desc="Participants can register online and submit any required materials."
          service3Img=""
          service3Title="Preparation"
          service3Desc="We provide resources and guidance to help participants prepare for the competition."
          service4Img=""
          service4Title="Competition Day"
          service4Desc="Participants compete in their respective events, judged by a panel of experts."
          service5Img=""
          service5Title="Awards"
          service5Desc="Winners are announced, and awards are presented at a special ceremony."
        />
      </section>

      <section className="p-5 sm:20">
        <CallNow />
      </section>

      <Footer />
    </>
  );
}
