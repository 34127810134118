import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Team from "../components/Team";

const ContactPage = () => (
  <div>
    <Navbar />

    
   <div className="pt-24">
   <Team/>
   </div>
    <section class="bg-white p-5 sm:px-20">
      <div class="container px-6 py-12 mx-auto">
        <div>
          <p class="font-medium">Contact us</p>

          <h1 class="mt-2 text-2xl font-semibold text-gray-800 md:text-3x">Get in touch</h1>

          <p class="mt-3 text-gray-700">Our friendly team would love to hear from you.</p>
        </div>

        <div class="mt-10">
          <div class="flex gap-10">
            <div>
              <span class="inline-block p-3 text-white rounded-full bg-blue-100/80 dark:bg-gray-800">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>
              </span>

              <h2 class="mt-4 text-base font-medium text-gray-800 dark:text-white">Email</h2>
              <p class="mt-2 text-sm ">Our friendly team is here to help.</p>
              <p class="mt-2 text-sm">
                <a href="mailto:contact@primalegal.co.in" className="text-red-500">contact@primalegal.co.in</a>
              </p>
            </div>

            <div>
              <span class="inline-block p-3 text-white rounded-full bg-blue-100/80 dark:bg-gray-800">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                </svg>
              </span>

              <h2 class="mt-4 text-base font-medium text-gray-800 dark:text-white">Office</h2>
              <p class="mt-2 text-sm ">Kolkata and Indore</p>
            </div>
          </div>
        </div>
      </div>
    </section>


 

    <Footer />
    
  </div>
);
export default ContactPage;
