import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import client from "../client";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import BannerSection from "../components/BannerSection";

import webinarBG from "../assets/img/webinar-bg.jpg";
import bannerImg2 from "../assets/img/banner2.jpg";

export default function Blog() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "post"] {
        title,
        slug,
        body,
        categories[] -> {
        title,
        },
        mainImage {
          asset -> {
            _id,
            url
          },
          alt
        }
      }`
      )
      .then((data) => setPosts(data))
      .catch(console.error);
  }, []);

  const webinarPosts = posts.filter((post) =>
    post.categories.some(
      (category) => category.title.toLowerCase() === "webinar"
    )
  );

  return (
    <>
      <Navbar />

      <BannerSection
        bannerImg={webinarBG}
        bannerImg2={bannerImg2}
        title="Webinars: Gain Insights, Stay Updated, and Enhance Your Knowledge with Our Expert-Led Sessions"
        subtitle="Join our webinars to gain valuable insights, stay updated on legal trends, and enhance your knowledge from experts."
      />

      <section className="mt-0 p-5 sm:p-20">
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
          {webinarPosts.map((post) => (
            <article
              key={post.slug.current}
              className="bg-gray-100 rounded-lg p-5"
            >
              <img
                src={post.mainImage.asset.url}
                alt={post.title}
                className="rounded-xl h-72"
              />
              <Link to={`/blog/${post.slug.current}`}>
                <h4 className="text-xl mt-2">{post.title}</h4>
              </Link>

              {/* {post.categories !== null && (
                <ul>
                  {post.categories.map((category, index) => (
                    <li key={index}>{category.title}</li>
                  ))}
                </ul>
              )} */}

              <button className="mt-5 mb-10">
                <Link
                  to={`/blog/${post.slug.current}`}
                  className="bg-red-600 hover:bg-red-500 transition-all px-6 py-1 rounded-sm text-white mt-10"
                >
                  Read More
                </Link>
              </button>
            </article>
          ))}
        </div>
      </section>

      <section className="p-5 sm:p-20">
        <h2 className="text-3xl pb-10">Our Webinars Cover</h2>
        <ul>
          <li className="pb-6">
            <span className="text-red-600 font-bold">
              1. Current Legal Trends:
            </span>{" "}
            Stay informed about the latest trends and developments in the legal
            field.
          </li>

          <li className="pb-6">
            <span className="text-red-600 font-bold">2. Expert Insights: </span>{" "}
            Gain insights from legal experts and practitioners on various
            topics.
          </li>

          <li className="pb-6">
            <span className="text-red-600 font-bold">3.Practical Skills: </span>
            Learn practical skills and strategies for legal practice and career
            development.
          </li>

          <li className="pb-6">
            <span className="text-red-600 font-bold">
              4. Interactive Sessions:{" "}
            </span>{" "}
            Engage with speakers and participants through interactive Q&A
            sessions.
          </li>
        </ul>
      </section>

      <section className="p-5 sm:p-20">
        <h2 className="text-3xl pb-10">Why Attend?</h2>
        <ul>
          <li className="pb-6">
            <span className="text-red-600 font-bold">
              1.Learning Opportunities:
            </span>{" "}
            Our webinars provide a convenient and accessible way to enhance your
            legal knowledge and skills.
          </li>

          <li className="pb-6">
            <span className="text-red-600 font-bold">2. Expert Speakers: </span>{" "}
            Hear from experienced legal professionals and thought leaders.
          </li>

          <li className="pb-6">
            <span className="text-red-600 font-bold">3. Networking: </span>
            Connect with peers and professionals from the legal community.
          </li>

          <li className="pb-6">
            <span className="text-red-600 font-bold">
              4. Continuing Education{" "}
            </span>
            Our webinars contribute to your continuing legal education and
            professional development.
          </li>
        </ul>
      </section>

      <Footer />
    </>
  );
}
