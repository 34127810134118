import React from "react";

import { Link } from "react-router-dom";

const Footer = () => (
  <footer>
     <footer class="bg-gray-900 text-white py-12">

    <div class="container mx-auto px-4">
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        
         
        <div>
          <h3 class="text-lg font-semibold mb-4">About Us</h3>
          <p class="text-sm">
            Prima Legal is a comprehensive service-based platform specializing in a wide range of legal services and educational resources. Our mission is to provide high-quality legal support and information, empowering our clients to navigate the complexities of the legal landscape confidently.
          </p>
        </div>
        
         
        <div>
          <h3 class="text-lg font-semibold mb-4">Quick Links</h3>
          <ul class="text-sm space-y-2 leading-7">
            <Link to="/"><li><a href={() => false} class="hover:text-gray-400">Home</a></li></Link>
            <Link to="/about"><li><a href={() => false} class="hover:text-gray-400">About Us</a></li></Link>
            <Link to="/blog"><li><a href={() => false} class="hover:text-gray-400">Blog</a></li></Link>
            <Link to="/contact"><li><a href={() => false} class="hover:text-gray-400">Contact</a></li></Link>
          </ul>
        </div>
        
         
        <div>
          <h3 class="text-lg font-semibold mb-4">Services</h3>
          <ul class="text-sm space-y-2 leading-7">
          <Link to="/agreement-drafting"><li><a href={() => false} class="hover:text-gray-400">Agreement Drafting</a></li></Link>
          <Link to="/legal-guidance"><li><a href={() => false} class="hover:text-gray-400">Legal Guidance</a></li></Link>
          <Link to="/legal-aid"><li><a href={() => false} class="hover:text-gray-400">Legal Aid</a></li></Link>
          <Link to="/competition"><li><a href={() => false} class="hover:text-gray-400">Competition</a></li></Link>
          <Link to="/call-for-papers"><li><a href={() => false} class="hover:text-gray-400">Call For Papers</a></li></Link>
          <Link to="/webinars"><li><a href={() => false} class="hover:text-gray-400">Webinar</a></li></Link>
          </ul>
        </div>
        
         
        <div>
          <h3 class="text-lg font-semibold mb-4">Contact Information</h3>
          <p class="text-sm mb-2">Email: <a href="mailto:contact@primalegal.co.in" class="hover:text-gray-400">contact@primalegal.co.in</a></p>
          <p class="text-sm mb-2">Address: Kolkata and Indore</p>
          <h3 class="text-lg font-semibold mb-4 mt-8">Follow Us</h3>
          <div class="flex space-x-4 flex-wrap">
            <a href="https://www.instagram.com/primalegal/?igsh=dzdycGR1YWFxM2Zl" target="_blank" class="hover:text-gray-400"><i class="fab fa-instagram"></i> Instagram</a>
            <a href="https://www.linkedin.com/in/prima-legal-84705431a/" target="_blank" class="hover:text-gray-400"><i class="fab fa-instagram"></i> LinkedIn</a>
          </div>
        </div>
      </div>
      
       
      
      
      
      <div class="mt-8 text-sm text-gray-500">
        <p>
          The information provided on this website is for general informational purposes only and does not constitute legal advice. Prima Legal is not responsible for any actions taken based on the information provided on this website. For personalized legal advice, please consult with one of our qualified legal professionals.
        </p>
      </div>
    </div>
  </footer>
  </footer>
);

export default Footer;
