import React from "react";
import callImg from "../assets/img/phone-call.png";

import bannerImg from "../assets/img/banner-1.png";
import bannerImg2 from "../assets/img/banner2.jpg";

import commanImg from "../assets/img/house.png";
import AreaOfPractice from "./AreaOfPractice";
import BannerSection from "./BannerSection";
// import Testimonials from "./Testimonials";

// Area Of Practice Image
import agreementDraft from "../assets/img/agreement.png";
import legalGuide from "../assets/img/legal.png";
import legalAid from "../assets/img/aid.png";
import callPapers from "../assets/img/papers.png";
import webinar from "../assets/img/webinar.png";
import WhyChooseUs from "./WhyChooseUs";
import CallNow from "./CallNow";

const HomeContent = () => (
  <div>
    <BannerSection
      bannerImg={bannerImg}
      bannerImg2={bannerImg2}
      title="Welcome to Prima Legal - Your Partner in Legal Excellence."
      subtitle="Expertise, Integrity, and Excellence in Legal Solutions"
    />

    <section className="p-5 sm:p-20">
      <div>
        <div className="mb-10">
          <h4>
            <span className="text-red-600 font-semibold">Prima Legal </span>is
            your trusted partner in navigating the legal landscape. Our mission
            is to provide high-quality legal support and information, ensuring
            our clients have the resources and advice they need to make informed
            decisions. Our platform offers access to an extensive collection of
            legal journals and educational resources, keeping you informed about
            the latest developments and trends in various legal fields. Our
            skilled paralegals provide tailored legal advice on a wide range of
            issues, ensuring you receive solutions specific to your needs.
          </h4>
        </div>

        <h3 className="sm:text-5xl text-2xl mb-4 font-thin text-center">
          Mission and Vision
        </h3>
        <p className="my-5">
          <span className="text-red-600 font-semibold">
            At Premier Law Firm
          </span>
          , our mission is to serve as a beacon of excellence and integrity
          within the legal community of Kolkata and beyond. We are dedicated to
          providing comprehensive, innovative, and personalized legal solutions
          tailored to meet the diverse needs of our clients. Our relentless
          pursuit of justice, coupled with unwavering ethical standards, ensures
          that we uphold the trust and confidence placed in us by those we
          serve. We are committed to fostering meaningful relationships built on
          transparency, respect, and empathy, and to empowering our clients with
          the knowledge and resources they need to navigate complex legal
          landscapes with confidence and assurance. Through our tireless
          advocacy, steadfast dedication to professional development, and
          unwavering commitment to the highest standards of legal practice, we
          strive to make a positive and lasting impact on the lives of our
          clients, our community, and the legal profession as a whole.
        </p>
        <p>
          Our vision at Premier Law Firm is to be recognized as the preeminent
          legal institution in Kolkata, renowned for our unparalleled commitment
          to excellence, innovation, and social responsibility. We aspire to be
          the trusted advisors and advocates for individuals, businesses, and
          organizations seeking expert legal guidance and representation. Our
          vision extends beyond the confines of traditional legal practice; we
          envision a future where justice is accessible to all, regardless of
          socioeconomic status, and where the rule of law serves as a
          cornerstone of a fair and equitable society. We are dedicated to
          pushing the boundaries of legal innovation, embracing emerging
          technologies, and pioneering new approaches to address the evolving
          needs and challenges of our clients and society at large. Through our
          collective efforts, we endeavor to shape the future of the legal
          profession, leaving a lasting legacy of integrity, compassion, and
          social impact that transcends generations.
        </p>
      </div>
    </section>

    <AreaOfPractice
      title="Experience a Smarter"
      subTitle="Legal Solution Platform in your hand"
      service1Img={agreementDraft}
      service1Title="Agreement Drafting"
      service1Desc=" Precision and clarity in contracts and agreements tailored to
              client needs."
      service2Img={legalGuide}
      service2Title="Legal Guidance"
      service2Desc="Expert advice across corporate, commercial, and family law for
              strategic solutions."
      service3Img={legalAid}
      service3Title="Legal Aid"
      service3Desc="We offer expert legal guidance across diverse areas including
              corporate law, commercial transactions, family law, and more. Our
              seasoned advisors provide strategic insights and solutions to
              navigate legal complexities and achieve favorable outcomes."
      service4Img={callPapers}
      service4Title="Calls for Papers"
      service4Desc="Inviting original legal research submissions for publication and
              scholarly discourse."
      service5Img={webinar}
      service5Title="Webinars"
      service5Desc="Expert-led online seminars providing insights into current legal
              issues and trends."
    />

    <div className="my-8">
      {/* <Testimonials /> */}
      <WhyChooseUs />
    </div>

    <section className="p-5 sm:p-20 mt-5 flex justify-between flex-col md:flex-row">
      <div className="left">
        <div className="boxes flex gap-5 flex-col rounded-lg shadow-lg p-5 relative w-5/6 md:w-6/6">
          <div className="flex items-center gap-2">
            <img src={commanImg} alt="banner img" className="h-8" />
            <p>Save Time and Money</p>
          </div>

          <div className="flex items-center gap-2">
            <img src={commanImg} alt="banner img" className="h-8" />
            <p>Best Advice that Maters</p>
          </div>

          <div className="flex items-center gap-2">
            <img src={commanImg} alt="banner img" className="h-8" />
            <p>Private and Confidentail Calls</p>
          </div>

          <div className="flex items-center gap-2">
            <img src={commanImg} alt="banner img" className="h-8" />
            <p>Instant Advise</p>
          </div>

          <div className="call absolute -top-3 md:-right-20 -right-0 bg-slate-100 px-3 py-2 rounded-lg flex items-center gap-2 justify-between ">
            <img
              src={callImg}
              alt="banner img"
              className="h-8 bg-green-300 p-2 rounded-lg"
            />
            <p className="">Call</p>
          </div>

          <div className="video absolute -bottom-10 md:-left-10 -left-0 bg-slate-100 px-3 py-2 rounded-lg flex items-center gap-2 justify-between">
            <img
              src={callImg}
              alt="banner img"
              className="h-8 bg-red-300 p-2 rounded-lg"
            />
            <p className="">Video call</p>
          </div>

          <div className="documnet absolute md:bottom-10 -bottom-5 md:-right-32 -right-0 bg-slate-100 px-3 py-2 rounded-lg flex items-center gap-2 justify-between">
            <img
              src={callImg}
              alt="banner img"
              className="h-8 bg-blue-300 p-2 rounded-lg"
            />
            <p className="">Documents</p>
          </div>
        </div>
      </div>
      <div className="right w-6/6 md:w-2/6  mt-28 md:mt-0">
        <h3 className="text-4xl mb-4">Connect with Our</h3>{" "}
        <span className="text-4xl mt-12 text-red-600 font-bold">
          Legal Advisors
        </span>
        <p className="mt-3">
        Choosing Prima Legal means gaining access to a team of experienced professionals dedicated to your legal success.
        </p>
        <a href="mailto:contact@primalegal.co.in">
          <button className="bg-red-600 hover:bg-red-500 transition-all px-6 py-1 rounded-sm text-white mt-10">
            Talk to Lawyer
          </button>
        </a>
      </div>
    </section>

<CallNow/>

  </div>
);

export default HomeContent;
