import React from "react";

import SwatiProfile from "../assets/img/Swati.jpg"
import MeghaProfile from "../assets/img/Megha.jpg"
import RohitProfile from "../assets/img/rohit.JPG"

import Email from '../../src/assets/img/email.png';

const Team = () => {
  return (
    <>
      <div class="mb-16">
        <div class="container flex justify-center mx-auto pt-16">
          <div>
            <p class="text-gray-500 text-lg text-center font-normal pb-3">
              BUILDING TEAM
            </p>
            <h1 class="xl:text-4xl text-3xl text-center text-gray-800 font-extrabold pb-6 sm:w-4/6 w-5/6 mx-auto">
              The Talented People Behind the Scenes of the Organization
            </h1>
          </div>
        </div>
        <div class="w-full bg-gray-100 px-10 pt-10">
          <div class="container mx-auto">
            <div
              role="list"
              aria-label="Behind the scenes People "
              class="lg:flex md:flex sm:flex items-center xl:justify-between flex-wrap md:justify-around sm:justify-around lg:justify-around">


              <div role="listitem" class="xl:w-1/3 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5">
                <div class="rounded overflow-hidden shadow-md bg-white">
                  <div class="absolute -mt-20 w-full flex justify-center">
                    <div class="h-32 w-32">
                      <img src={RohitProfile} alt="Rohit Kumar Dugar" class="rounded-full object-cover h-full w-full shadow-md"/>
                    </div>
                  </div>
                  <div class="px-6 mt-16 pb-5">
                    <h1 class="font-bold text-3xl text-center mb-1">
                    <a href="mailto:rohit.dugar@primalegal.co.in"> Rohit Kumar Dugar</a>
                    </h1>
                    <p class="text-center text-gray-600 text-base pt-3 font-normal">
                    Rohit Kumar Dugar is a seasoned legal professional with a deep-rooted interest in Intellectual Property Rights, Sports Law, Media and Entertainment Law, and Corporate & Business Law. His extensive knowledge, honed through years of learning, is complemented by a strong academic foundation, including multiple publications in esteemed journals and two authored books. 
                    </p>
                       <div className="flex justify-end items-center flex-col mt-5 ">
                         <a href="mailto:rohit.dugar@primalegal.co.in">
                         <img src={Email} className="h-7 cursor-pointer" alt="email"/>
                         </a>
                       </div>
                  </div>
                </div>
              </div>


              <div role="listitem" class="xl:w-1/3 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5">
                <div class="rounded overflow-hidden shadow-md bg-white">
                  <div class="absolute -mt-20 w-full flex justify-center">
                    <div class="h-32 w-32">
                      <img src={SwatiProfile} alt="Adv. Swati Singh" class="rounded-full object-cover h-full w-full shadow-md"/>
                    </div>
                  </div>
                  <div class="px-6 mt-16 pb-5">
                    <h1 class="font-bold text-3xl text-center mb-1">
                    <a href="mailto:swati.singh@primalegal.co.in"> Adv. Swati Singh</a>
                    </h1>
                    <p class="text-center text-gray-600 text-base pt-3 font-normal">
                    Seasoned lawyer practicing at the Indore High Court with a strong academic background in law and commerce. With over four years of experience across diverse legal areas, she offers comprehensive legal solutions tailored to individual client needs. Committed to professionalism and client satisfaction, she provides personalized legal guidance and representation.
                    </p>

                    <div className="flex justify-end items-center flex-col mt-5 ">
                         <a href="mailto:swati.singh@primalegal.co.in">
                         <img src={Email} className="h-7 cursor-pointer" alt="email"/>
                         </a>
                       </div>
                    
                  </div>
                </div>
              </div>

              <div role="listitem" class="xl:w-1/3 sm:w-3/4 md:w-2/5 relative mt-16 mb-32 sm:mb-24 xl:max-w-sm lg:w-2/5">
                <div class="rounded overflow-hidden shadow-md bg-white">
                  <div class="absolute -mt-20 w-full flex justify-center">
                    <div class="h-32 w-32">
                      <img src={MeghaProfile} alt="Megha Bhargava" class="rounded-full object-cover h-full w-full shadow-md"/>
                    </div>
                  </div>
                  <div class="px-6 mt-16 pb-5">
                    <h1 class="font-bold text-3xl text-center mb-1">
                    <a href="mailto:megha.bhargava@primalegal.co.in">Megha Bhargava</a>
                    </h1>
                    <p class="text-center text-gray-600 text-base pt-3 font-normal">
                    Megha Bhargava is a final-year law student at St. Xavier's University, Kolkata. My passion lies in entertainment and media law, and I aspire to carve out a career in corporate law. Beyond academics, I enjoy researching and exploring new ideas and keeping up with fashion trends, which complement my interest in the dynamic fields of law I aim to specialize in.
                    </p>
                    <div className="flex justify-end items-center flex-col mt-5 ">
                         <a href="mailto:megha.bhargava@primalegal.co.in">
                         <img src={Email} className="h-7 cursor-pointer" alt="mail"/>
                         </a>
                       </div>
                  </div>
                </div>
              </div>
              
              
               
              
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
