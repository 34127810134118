import React from "react";
import Navbar from "../components/Navbar";

import legalAid from "../assets/img/legal-aid.png";
import bannerImg2 from "../assets/img/banner2.jpg";
import BannerSection from "../components/BannerSection";
import CallNow from "../components/CallNow";
import Footer from "../components/Footer";
import AreaOfPractice from "../components/AreaOfPractice";

const LegalAid = () => {
  return (
    <div>
      <Navbar />

      <BannerSection
        bannerImg={legalAid}
        bannerImg2={bannerImg2}
        title="Offering Legal Support and Representation to Those Who Need It Most"
        subtitle="We provide essential legal support and representation, ensuring access to justice for everyone."
      />

      <section className="p-5 sm:p-20 mt-5">
        <p>
          At Prima Legal, we believe in ensuring access to justice for everyone.
          Our Legal Aid service is dedicated to providing support and assistance
          to individuals who need legal help but may not have the means to
          afford it. We conduct legal camps and offer representation to help
          those in need.
        </p>
      </section>

      <section class="bg-gray-100 p-5 sm:p-20">
        <div class="container px-6 py-10 mx-auto">
          <h1 class="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl text-black">
          Our Commitment
          </h1>

          <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-2 xl:grid-cols-3">
            <div class="p-8 space-y-3 border-2 border-red-400  rounded-xl">
              <span class="inline-block text-red-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-8 h-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"
                  />
                </svg>
              </span>

              <h1 class="text-xl font-semiboldcapitalize text-black">
                Accessibility
              </h1>

              <p class="text-gray-900">
              We make legal services accessible to all, regardless of financial status. We believe that everyone deserves legal representation and support, and we work to remove barriers to access.
              </p>
            </div>

            <div class="p-8 space-y-3 border-2 border-red-400 rounded-xl">
              <span class="inline-block text-red-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-8 h-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
                  />
                </svg>
              </span>

              <h1 class="text-xl font-semiboldcapitalize text-black">
              Representation
              </h1>
              <p class="text-gray-900">
              Our team provides representation and legal assistance to ensure fair treatment and justice. We handle a variety of cases, from civil disputes to criminal defense, ensuring that our clients receive the support they need.
              </p>
            </div>

            <div class="p-8 space-y-3 border-2 border-red-400 rounded-xl">
              <span class="inline-block text-red-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-8 h-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                  />
                </svg>
              </span>

              <h1 class="text-xl font-semiboldcapitalize text-black">
              Community Engagement
              </h1>

              <p class="text-gray-900">
              We actively engage with communities to offer legal education and support. Our legal camps and workshops provide valuable information and resources to those in need.
              </p>
            </div>
          </div>
        </div>
      </section>
    
    
      <section className="p-5 sm:20">
        <AreaOfPractice
          title="Areas of Expertise"
          subTitle=""
          service1Img= ""
          service1Title="Outreach"
          service1Desc="We conduct outreach programs to identify individuals and communities in need of legal aid."
          service2Img=""
          service2Title="Consultation"
          service2Desc="We offer free consultations to understand the specific legal issues and needs of our clients."
          service3Img=""
          service3Title="Representation"
          service3Desc="Our team provides legal representation in court and during negotiations."
          service4Img=""
          service4Title="Support"
          service4Desc="We offer ongoing support and follow-up to ensure our clients' needs are met."
          service5Img=""
          service5Title="Education"
          service5Desc="We conduct workshops and seminars to educate communities about their legal rights and responsibilities."
        />
      </section>
  
  <section className="p-5 sm:20">
    <CallNow/>
  </section>

      <Footer />
    </div>
  );
};

export default LegalAid;
