// import { Routes, Route, useLocation, Router, BrowserRouter } from "react-router-dom";
import { Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";
import AboutPage from "./pages/AboutPage";
import Homepage from "./pages/Homepage";
import ContactPage from "./pages/ContactPage";
import AgreementDrafting from "./pages/AgreementDrafting";
import LegalGuidance from "./pages/LegalGuidance";
import Competition from "./pages/Competition";
import CallForPapers from "./pages/CallForPapers";
import Webinars from "./pages/Webinars";
import LegalAid from "./pages/LegalAid";
import Error from "./components/Error";

import Blog from "./components/Blog";
import SinglePost from "./components/SinglePost";
import AdminLogin from "./pages/AdminLogin";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="mainApp">
      <Routes>
        <Route path="/" exact element={<Homepage />}></Route>
        <Route path="/about" element={<AboutPage />}></Route>
        <Route path="/contact" element={<ContactPage />}></Route>
        <Route path="/agreement-drafting" element={<AgreementDrafting />}></Route>
        <Route path="/legal-guidance" element={<LegalGuidance />}></Route>
        <Route path="/competition" element={<Competition />}></Route>
        <Route path="/call-for-papers" element={<CallForPapers />}></Route>
        <Route path="/webinars" element={<Webinars />}></Route>
        <Route path="/legal-aid" element={<LegalAid/>} ></Route>
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:slug" element={<SinglePost/>} />
        <Route path="*"  element={<Error/>} ></Route>
        <Route path="/admin" element={<AdminLogin/>} ></Route>
      </Routes>
    </div>
  );
}

export default App;
