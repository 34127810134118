import callForPapers from "../assets/img/banner-8.png";
import bannerImg2 from "../assets/img/banner2.jpg";
import BannerSection from "../components/BannerSection";
import AreaOfPractice from "../components/AreaOfPractice";

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import client from "../client";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default function CallForPapers() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "post"] {
        title,
        slug,
        body,
        categories[] -> {
        title,
        },
        mainImage {
          asset -> {
            _id,
            url
          },
          alt
        }
      }`
      )
      .then((data) => setPosts(data))
      .catch(console.error);
  }, []);

  const callForPapersPosts = posts.filter((post) =>
    post.categories.some(
      (category) => category.title.toLowerCase() === "callforpaper"
    )
  );

  return (
    <>
      <Navbar />

      <BannerSection
        bannerImg={callForPapers}
        bannerImg2={bannerImg2}
        title="Call for Papers: Submit Your Research and Articles to Advance Legal Knowledge"
        subtitle="Share your insights and contribute to scholarly discussions by submitting your legal research and articles."
      />

      <section className="mt-10 p-5 sm:p-20">
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
          {callForPapersPosts.map((post) => (
            <article
              key={post.slug.current}
              className="bg-gray-100 rounded-lg p-5"
            >
              <img
                src={post.mainImage.asset.url}
                alt={post.title}
                className="rounded-xl h-72"
              />
              <Link to={`/blog/${post.slug.current}`}>
                <h4 className="text-xl mt-2">{post.title}</h4>
              </Link>

              {/* {post.categories !== null && (
                <ul>
                  {post.categories.map((category, index) => (
                    <li key={index}>{category.title}</li>
                  ))}
                </ul>
              )} */}

              <button className="mt-5 mb-10">
                <Link
                  to={`/blog/${post.slug.current}`}
                  className="bg-red-600 hover:bg-red-500 transition-all px-6 py-1 rounded-sm text-white mt-10"
                >
                  Read More
                </Link>
              </button>
            </article>
          ))}
        </div>
      </section>

      <section className="p-5 sm:p-20 mt-5">
        <p>
          At Prima Legal, we value the importance of legal scholarship and the
          dissemination of knowledge. Our Call for Papers invites submissions of
          legal research and articles from students, academics, and
          professionals. We aim to contribute to the ongoing discussion and
          development of legal knowledge.
        </p>
      </section>

      <section className="p-5 sm:20">
        <AreaOfPractice
          title="How We Work"
          subTitle=""
          service1Img=""
          service1Title="Submission"
          service1Desc="Authors can submit their papers through our online submission portal."
          service2Img=""
          service2Title="Review"
          service2Desc="Our panel of experts reviews the submissions for quality, originality, and relevance."
          service3Img=""
          service3Title="Feedback"
          service3Desc="Authors receive feedback and may be asked to revise their papers."
          service4Img=""
          service4Title="Publication"
          service4Desc="Selected papers are published in our legal journal and featured on our website."
          service5Img=""
          service5Title="Recognition"
          service5Desc="Authors of published papers receive certificates and recognition at our annual conference."
        />
      </section>

      <section class="bg-gray-100 p-5 sm:p-20">
        <div class="container px-6 py-10 mx-auto">
          <h1 class="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl text-black">
            Impact of Our Call for Papers:
          </h1>

          <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-2 xl:grid-cols-3">
            <div class="p-8 space-y-3 border-2 border-red-400  rounded-xl">
              <span class="inline-block text-red-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-8 h-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"
                  />
                </svg>
              </span>

              <h1 class="text-xl font-semiboldcapitalize text-black">
                Knowledge Dissemination
              </h1>

              <p class="text-gray-900">
                Our platform helps spread new ideas and research, contributing
                to the advancement of legal knowledge.
              </p>
            </div>

            <div class="p-8 space-y-3 border-2 border-red-400 rounded-xl">
              <span class="inline-block text-red-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-8 h-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
                  />
                </svg>
              </span>

              <h1 class="text-xl font-semiboldcapitalize text-black">
                Professional Development
              </h1>
              <p class="text-gray-900">
                Authors gain valuable experience in legal research and writing,
                enhancing their professional skills.
              </p>
            </div>

            <div class="p-8 space-y-3 border-2 border-red-400 rounded-xl">
              <span class="inline-block text-red-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-8 h-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                  />
                </svg>
              </span>

              <h1 class="text-xl font-semiboldcapitalize text-black">
                Community Engagement
              </h1>
              <p class="text-gray-900">
                Our Call for Papers fosters a community of scholars and
                practitioners dedicated to legal research and education.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
