import React from "react";
import Navbar from "../components/Navbar";

import legalGuidance from "../assets/img/legal-guidance.png";
import bannerImg2 from "../assets/img/banner2.jpg";
import BannerSection from "../components/BannerSection";
import CallNow from "../components/CallNow";
import AreaOfPractice from "../components/AreaOfPractice";
import Footer from "../components/Footer";

const LegalGuidance = () => {
  return (
    <div>
      <Navbar />

      <BannerSection bannerImg={legalGuidance} bannerImg2={bannerImg2} title="Providing Personalized and Expert Legal Advice to Navigate Complex Issues" subtitle="Our team offers expert legal advice on a wide range of issues, ensuring you receive accurate and tailored guidance." />

        
      <section className="p-5 sm:p-20 mt-5">
        <p className="my-10">
          <span className="text-red-600 font-bold">At Prima Legal</span> , we
          understand the importance of having clear, concise, and legally sound
          agreements. Our Agreement Drafting service ensures that all contracts
          and agreements are tailored to meet your specific needs while
          protecting your interests.
        </p>
        <p className="my-10">
          Our team is adept at drafting, reviewing, and negotiating various
          types of agreements, including:
        </p>

        <ul>
          <li className="mb-3">
            1. <span className="text-red-600">Employment Contracts :</span>
            Drafting agreements that outline the terms of employment, ensuring
            both employer and employee rights are protected.
          </li>
          <li className="mb-3">
            2.
            <span className="text-red-600">
              Non-Disclosure Agreements (NDAs):
            </span>
            Creating agreements to safeguard sensitive information and maintain
            confidentiality.
          </li>
          <li className="mb-3">
            3. <span className="text-red-600">Service Agreements:</span>
            Structuring contracts that define the terms and conditions of
            service provision.
          </li>
          <li className="mb-3">
            4. <span className="text-red-600">Partnership Agreements:</span>
            Formulating agreements that delineate the responsibilities and
            profit-sharing among partners.
          </li>
          <li className="mb-3">
            5.
            <span className="text-red-600">Sale and Purchase Agreements:</span>
            Drafting documents that govern the sale and purchase of goods or
            services, ensuring clarity and fairness.
          </li>
        </ul>
      </section>

      <section className="p-5 sm:20">
        <AreaOfPractice
          title="Areas of Expertise"
          subTitle=""
          service1Img= ""
          service1Title="Contract Law"
          service1Desc="Offering advice on the formation, interpretation, and enforcement of contracts. We help you understand your rights and obligations under various agreements."
          service2Img=""
          service2Title="Corporate Governance"
          service2Desc="Providing guidance on best practices for managing corporate affairs and ensuring compliance with legal standards. This includes advising on board responsibilities, shareholder rights, and corporate policies."
          service3Img=""
          service3Title="Intellectual Property Law"
          service3Desc="Advising on the protection of innovations, trademarks, copyrights, and patents. We help you secure your intellectual property and protect it from infringement."
          service4Img=""
          service4Title="Legal Compliance"
          service4Desc="Assisting with the identification and adherence to relevant laws and regulations. We help you stay compliant with industry standards and legal requirements."
          service5Img=""
          service5Title="Risk Management"
          service5Desc="Helping clients identify, assess, and mitigate potential legal risks. We provide strategies to manage and reduce risks associated with business operations and legal decisions."
        />
      </section>

      <section className="p-5 sm:20">
        <CallNow/>
      </section>


      <Footer />
    </div>
  );
};

export default LegalGuidance;
